import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { ReactMultiEmail } from "react-multi-email";
import axios from "axios";
import "react-multi-email/dist/style.css";

function AdminAgentManage() {
  const [agentList, setAgentList] = useState([]);
  const [selectedAgents, setSelectedAgent] = useState([]);
  const [inputEmails, setInputEmails] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/allAgentList`)
      .then((dd) => {
        setAgentList(dd.data);
      });
  }, []);

  const addEmail = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/updateEmailAgent`,
        { agentIds: selectedAgents, emails: inputEmails }
      );
      console.log(data);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <Box>
      <Box>
        <p>Agent list</p>
        <Select
          multiple
          value={selectedAgents}
          onChange={(e) => setSelectedAgent(e.target.value)}
        >
          {agentList.map((item, i) => (
            <MenuItem value={item.id} key={i}>
              {item.agentName}
            </MenuItem>
          ))}
        </Select>
        {/* {selectedAgents && (
          <p>
            {`Exiting Emails:- 
            ${agentList
              .find((it) => it.id === selectedAgents)
              ?.EmailList?.join(", ")}`}
          </p>
        )} */}
      </Box>
      <Box mt={4}>
        <p>Email</p>

        <ReactMultiEmail
          placeholder="Input your email"
          emails={inputEmails}
          onChange={(_emails) => {
            setInputEmails(_emails.map((item) => item.toLowerCase()));
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
        <p>{inputEmails.join(", ") || "empty"}</p>
      </Box>

      <Button variant="contained" onClick={addEmail} sx={{ mt: 6 }}>
        Add email to agent
      </Button>
    </Box>
  );
}

export default AdminAgentManage;
