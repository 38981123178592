import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";

function ExpiredSubscription() {
  const user = useSelector((state) => state.user);

  return (
    <Box
      sx={{
        mt: 20,
      }}
    >
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        Your subscription is not active.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          mt: 10,
        }}
      >
        <h3>Subscription status: {user?.subscription?.status}</h3>

        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            ml: { xs: 0, md: 10 },
            mt: { xs: 2, md: 0 },
          }}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${user.email}`
            );
          }}
        >
          <SettingsIcon sx={{ mr: 2 }} />
          Manage subscription
        </Link>
      </Box>
    </Box>
  );
}

export default ExpiredSubscription;
