import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  // FormControlLabel,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirm } from "material-ui-confirm";
import { uploadData } from "aws-amplify/storage";

// import IOSSwitch from "../Components/IOSSwitch";
import FormField from "../assets/theme/overrides/FormField";
import { setAgentList } from "../redux/reducer";

const defaultColumns = [
  {
    field: "id",
    headerName: "id",
    hideable: false,
    filterable: false,
  },
  {
    field: "agentName",
    headerName: "Agent Name",
  },
  {
    field: "systemPrompt",
    headerName: "System prompt",
  },
  {
    field: "isWebTool",
    headerName: "Web search tool",
  },
  {
    field: "userId",
    headerName: "User Id",
  },
];

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function AddAgent({ onSuccess, editData, onBack }) {
  const confirm = useConfirm();
  // const dispatch = useDispatch();

  const [editFileArr, setEditFileArr] = useState([]);
  const [delFileIds, setDelFileIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadCount, setFileUploadCount] = useState(-1);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [isFileLoaded, setIsFileLoaded] = useState(false);

  const user = useSelector((state) => state.user);
  const isEdit = Boolean(editData);

  useEffect(() => {
    if (editData?.vector_storeId) {
      setIsFileLoaded(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/agent/getFiles`, {
          vector_storeId: editData?.vector_storeId,
        })
        .then(({ data }) => {
          setEditFileArr(data);
          setIsFileLoaded(false);
        })
        .catch((e) => {
          console.log(e);
          setIsFileLoaded(false);
        });
    }
  }, [editData]);

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("agentName", data?.agentName);
      formData.append("systemPrompt", data?.systemPrompt);
      formData.append("isWebTool", data?.isWebTool);
      formData.append("agentLabel", data?.agentLabel);
      formData.append("agentText", data?.agentText);
      formData.append("userId", user.sub);
      formData.append("email", user.email);
      formData.append("delFileIds", JSON.stringify(delFileIds));

      if (data.files.length > 0) setFileUploadCount(0);
      for (const file of data?.files) {
        await uploadData({
          path: `public/${user.sub}/agentFiles/${data?.agentName}/${file.name}`,
          data: file,
          options: {
            contentType: file.type,
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                setFileUploadProgress(
                  Math.round((transferredBytes / totalBytes) * 100)
                );
              }
            },
          },
        }).result;
        setFileUploadCount((prev) => prev + 1);
      }

      if (isEdit) {
        formData.append("id", editData.id);
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${
          editData ? "updateAgent" : "addAgent"
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      toast.success("Agent added successfully");
      onSuccess();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong");
      setFileUploadCount(0);
      setFileUploadProgress(0);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          height: "calc(100vh - 50px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: { xs: 10, xl: 5 },
          }}
        >
          <ArrowBackIcon onClick={onBack} />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {isEdit ? "Edit" : `Add New`} Agent
          </Typography>
        </Box>
        <Formik
          initialValues={{
            agentName: editData?.agentName || "",
            systemPrompt: editData?.systemPrompt || "",
            agentLabel: editData?.agentLabel || "",
            agentText: editData?.agentText || "",
            isWebTool: editData?.isWebTool === "false" ? false : true,
            files: [],
          }}
          validationSchema={yup.object().shape({
            agentName: yup.string().required("Agent tName is required"),
            systemPrompt: yup.string().required("System Prompt is required"),
            agentLabel: yup.string().required("Agent Label is required"),
            agentText: yup.string().required("Agent Text is required"),
            isWebTool: yup.boolean().required("Web tool is required"),
            files: yup.mixed(),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleBlur }) => (
            <Form>
              <Box>
                <FormField label="Agent Name" name="agentName" type="text" />
              </Box>
              <Box id="formfield" sx={{ mt: 3 }}>
                <FormField
                  multiline
                  minRows={5}
                  label="System Prompt"
                  name="systemPrompt"
                  type="text"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormField label="Agent Label" name="agentLabel" type="text" />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormField label="Agent Text" name="agentText" type="text" />
              </Box>
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    type="file"
                    onChange={(e) => setFieldValue("files", e.target.files)}
                    fullWidth
                    slotProps={{
                      htmlInput: {
                        multiple: "multiple",
                      },
                    }}
                    // disabled={isEdit}
                  />
                  {isFileLoaded && <CircularProgress sx={{ mt: 2 }} />}
                  {fileUploadCount > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography mb={1}>
                        {`Successfully uploaded Files ${fileUploadCount} out of ${values.files.length}`}
                      </Typography>
                      {fileUploadCount === values.files.length ? (
                        <Typography mb={1}>
                          {/* Files uploaded successfully! Creating your agent now... */}
                          <LinearProgress sx={{ width: "100%" }} />
                        </Typography>
                      ) : (
                        <Box>
                          {`Progress of file:- ${values.files[fileUploadCount]?.name}`}
                          <LinearProgressWithLabel value={fileUploadProgress} />
                        </Box>
                      )}
                    </Box>
                  )}
                  {editFileArr.length > 0 && (
                    <Box sx={{ overflow: "auto", maxHeight: 200, mt: 2 }}>
                      {editFileArr.map((item) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 2 }}
                        >
                          <Typography>{item.filename}</Typography>
                          <DeleteIcon
                            sx={{ ml: 2 }}
                            onClick={() =>
                              confirm({
                                description: "This action cannot be undo",
                              }).then(() => {
                                setDelFileIds([...delFileIds, item.fileId]);
                                setEditFileArr(
                                  editFileArr.filter(
                                    (it) => it.fileId !== item.fileId
                                  )
                                );
                              })
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>

              {/* <Box sx={{ mt: 4 }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ ml: 2 }}
                    onChange={(e) =>
                      setFieldValue("isWebTool", e.target.checked)
                    }
                    checked={values.isWebTool}
                    onBlur={handleBlur}
                    name="isWebTool"
                  />
                }
                label="Web search tool"
                labelPlacement="start"
                sx={{ ml: 0 }}
              />
              <ErrorMessage name="isWebTool" component="div" />
            </Box> */}

              <Box
                sx={{
                  mt: 4,
                  justifyContent: "center",
                  display: "flex",
                  paddingBottom: 2,
                }}
              >
                <LoadingButton
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "50%", md: "30%" },
                  }}
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {isEdit ? "Save Agent" : "Add Agent"}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
        <Box />
      </Box>
    </Container>
  );
}

const ManageAgent = () => {
  const [editData, setEditData] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [isAddAgent, setIsAddAgent] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentList = useSelector((state) => state.agentList);

  const handleDelete = async (id) => {
    setDeletingId(id);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/deleteAgent`, {
        id: id,
      });
      await fetchData();
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setDeletingId(null);
    }
  };

  useEffect(() => {
    if (state?.isAddAgent) setIsAddAgent(state?.isAddAgent);
  }, [state]);

  const user = useSelector((state) => state.user);

  const columns = [
    ...defaultColumns,
    {
      headerName: "Actions",
      field: "Add",
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                width: "100%",
              }}
            >
              <EditIcon
                onClick={() => {
                  setIsAddAgent(true);
                  setEditData(params.row);
                }}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "18px", sm: "24px" },
                }}
              />
              {user?.sub === params.row.userId && (
                <>
                  {deletingId === params.row.id ? (
                    <CircularProgress size={20} sx={{ ml: { xs: 1, sm: 3 } }} />
                  ) : (
                    <DeleteIcon
                      onClick={() => handleDelete(params.row.id)}
                      sx={{
                        ml: { xs: 1, sm: 3 },
                        cursor: "pointer",
                        fontSize: { xs: "18px", sm: "24px" },
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/agentList/${user.userRole}/${user.email}`
      )
      .then(({ data }) => {
        dispatch(setAgentList(data));
      });
  };

  useEffect(() => {
    if (user?.sub) fetchData();
  }, [user]);

  const onBack = () => {
    if (isAddAgent) {
      setIsAddAgent(false);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Box
      sx={{
        m: { xs: 1, sm: 2, md: 5, xl: 10 },
      }}
    >
      {isAddAgent ? (
        <AddAgent
          onSuccess={() => {
            setIsAddAgent(false);
            fetchData();
          }}
          editData={editData}
          onBack={onBack}
        />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 5,
              paddingTop: { xs: 10, xl: 5 },
            }}
          >
            <ArrowBackIcon onClick={onBack} />
            <Button
              variant="contained"
              onClick={() => {
                setIsAddAgent(true);
                setEditData(false);
              }}
              size="large"
            >
              Add agent
            </Button>
          </Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
              mb: 2,
              textAlign: "center",
            }}
          >
            Agent List
          </Typography>
          <DataGrid
            rows={agentList}
            columns={columns.map((it) => ({ ...it, flex: 1 }))}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ManageAgent;
