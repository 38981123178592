import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

const MuiListItem = styled(ListItem)(() => ({
  paddingTop: "0",
  paddingBottom: "0",
  fontSize: "16px",
  fontWeight: 400,
}));
const MuiListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: "10px",
  marginRight: "10px",
}));
const MuiListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.grey.gray_500,
}));

const PromptHolder = () => {
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);
  const user = useSelector((state) => state.user);

  const name = user?.given_name?.split(" ")?.[0];
  const formattedName =
    name?.[0]?.toUpperCase() + name?.slice(1)?.toLowerCase();

  const currentHour = new Date().getHours();

  let greeting;
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Afternoon";
  } else {
    greeting = "Evening";
  }

  return (
    <Box
      style={{ margin: "auto" }}
      sx={{
        mx: { xs: 0, sm: 3, md: 4 },
        maxWidth: {
          xs: "350px",
          sm: "540px",
          md: "800px",
        },
        // paddingBottom: { xs: "17vh", md: 0 },
        paddingBottom: { xs: "0vh", md: 0 },
      }}
      // className={classes.lendingSize}
    >
      {typeOfAg === "UX" ? (
        <>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey.gray_500,
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            Hi there, I am your helpful and flexible UX Assistant and can help
            you with creating amazing experiences for people!
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.grey.gray_500,
              fontSize: "16px",
              fontWeight: 700,
              mt: 5,
            }}
          >
            Some things I can help with:
          </Typography>
          <Box mt={2}>
            {[
              `<b>Conduct competitive reviews</b>. Eg “Conduct a competitive review of the best consumer banking websites”`,
              `<b>Conduct a UX expert or heuristic review.</b> Eg. “Please conduct a UX expert review of www.apple.com"`,
              `<b>Accessibility audits.</b> Eg “Conduct an Accessibility audit of www.apple.com`,
              `<b>Create personas.</b> Eg “Create three personas for the www.apple.com website”`,
              `<b>Create interview questions.</b> Eg “Create interview questions to assess the UX of www.apple.com"`,
              `<b>Transcribe interviews.</b>  Upload an audio file and I will email you a transcript in a Word doc (max 25MB file size)`,
              `<b>Report writing and interview summaries.</b> Upload interview transcripts (in PDF format) and just ask me to write a UX report. Tell me the headings you want or let me work that out for you.`,
              `<b>Design critiques.</b> Upload a screenshot of your designs from Figma (or any other tool) and I can provide a design critique.`,
              `<b>UX Humor.</b> Eg “Tell me a UX joke”`,
              `<b>AMA.</b> Ask me anything!`,
            ].map((item) => (
              <Box sx={{ display: "flex", ml: 2 }}>
                <MuiListItemIcon>
                  <FiberManualRecordIcon sx={{ width: "10px" }} />
                </MuiListItemIcon>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: 2,
                    color: (theme) => theme.palette.grey.gray_500,
                  }}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : typeOfAg === "MUFG SuperChat" || typeOfAg === "PiP SuperChat" ? (
        <>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.grey.gray_500,
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Hi there, I'm SuperChat. How can I help you today?
          </Typography>
        </>
      ) : // ) : ["meetingAssistant"].includes(typeOfAg) ? (
      //   <>
      //     <Typography
      //       variant="body1"
      //       sx={{
      //         color: (theme) => theme.palette.grey.gray_500,
      //         lineHeight: "normal",
      //         fontSize: "18px",
      //         fontWeight: 700,
      //       }}
      //     >
      //       How to get started
      //     </Typography>

      //     <List>
      //       <MuiListItem>
      //         <MuiListItemIcon>
      //           <FiberManualRecordIcon sx={{ width: "10px" }} />
      //         </MuiListItemIcon>
      //         <MuiListItemText
      //           primary={`Ask me a question about anything by typing in the chat bar. `}
      //         />
      //       </MuiListItem>
      //       <MuiListItem>
      //         <MuiListItemIcon>
      //           <FiberManualRecordIcon sx={{ width: "10px" }} />
      //         </MuiListItemIcon>
      //         <MuiListItemText>
      //           Ask me anything about a document, just open Sources (
      //           <Icon
      //             icon="material-symbols:folder-open-outline"
      //             style={{ margin: "-4px 0px" }}
      //           />
      //           ) from the chat bar to upload a pdf.
      //         </MuiListItemText>
      //       </MuiListItem>
      //     </List>
      //     <Typography
      //       variant="body1"
      //       sx={{
      //         mt: { xs: 1, sm: 1 },
      //         color: (theme) => theme.palette.grey.gray_500,
      //         lineHeight: "normal",
      //         fontSize: "18px",
      //         fontWeight: 700,
      //       }}
      //     >
      //       Try these prompts:
      //     </Typography>
      //     <List sx={{ fontSize: "16px", fontWeight: 400 }}>
      //       {[
      //         "Please write a summary of this document",
      //         "Write a structured report on the attached document",
      //         "Summaries the performance of my business over the past quarter based on this document",
      //         "Plot the bitcoin chart of 2023 YTD",
      //         "Create an image of a white siamese cat",
      //         "Please conduct UI/UX review for this site https://github.com/",
      //         "Analyze the given image",
      //       ].map((item) => (
      //         <MuiListItem>
      //           <MuiListItemIcon>
      //             <FiberManualRecordIcon sx={{ width: "10px" }} />
      //           </MuiListItemIcon>
      //           <MuiListItemText primary={item} />
      //         </MuiListItem>
      //       ))}
      //     </List>
      //     <Typography
      //       variant="body1"
      //       sx={{
      //         mt: { xs: 2, sm: 3 },
      //         color: (theme) => theme.palette.grey.gray_500,
      //         fontSize: "16px",
      //         fontWeight: 600,
      //       }}
      //     >
      //       What’s new 🎉
      //     </Typography>
      //     <List>
      //       {[
      //         "Web access",
      //         "Large file support",
      //         "Improved memory",
      //         "Image generation and analysis",
      //         "Advanced data analysis",
      //         "Transcribe (Speech to Text)",
      //       ].map((item) => (
      //         <MuiListItem>
      //           <MuiListItemIcon>
      //             <FiberManualRecordIcon sx={{ width: "10px" }} />
      //           </MuiListItemIcon>
      //           <MuiListItemText primary={item} />
      //         </MuiListItem>
      //       ))}
      //     </List>
      //   </>
      typeOfAg === "meetingAssistant" || typeOfAg === "default" ? (
        <>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.grey.gray_500,
              lineHeight: "normal",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            {greeting} {formattedName || ""}, how can I help?
          </Typography>
        </>
      ) : (
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette.grey.gray_500,
              lineHeight: "normal",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            {(user?.userRole === "student" || user?.userRole === "tutor") &&
              formattedName}
            {agentList.find((it) => it.agentName === typeOfAg)?.agentText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PromptHolder;
