import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormControlLabel,
  Slider,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Checkbox,
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled } from "@mui/material/styles";
import {
  setTypeOfAg,
  setIsRFormate,
  setSelectedAIModel,
  setTemperature,
  setVectorTypeDB,
  setPipelineTypeDoc,
  // setFlowPipeline,
  setIsTypingEffect,
  setDrawer,
  setIsAgentDropdownOpen,
} from "../redux/reducer";
import IOSSwitch from "./IOSSwitch";
import AIModelSelect, { AIModelList } from "./AIModelSelect";
import { getEmailDomain } from "../utils";

const rootStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  height: "100%",
  minWidth: "300px",
  // marginBottom: 10,
};
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "&.MuiToggleButtonGroup-root": {
    backgroundColor: "rgba(236,236,241,1)",
    borderRadius: 10,
    padding: 5,
  },
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: 10,
    borderColor: "rgba(207,207,214,0.4)",
    textTransform: "capitalize !important",
  },
  "& .Mui-selected": {
    backgroundColor: "white !important",
  },
}));

const MUFGAg = { agentName: "MUFG SuperChat" };
const PipGAg = { agentName: "PiP SuperChat" };
const markingAg = { agentName: "Marking Agent" };
export const defaultAgentList1 = [
  { agentName: "default" },
  { agentName: "meetingAssistant" },
  { agentName: "UX" },
];

function Sidebar({ handleClickOpen, openAIAPIStatus }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defaultAgentList, setDefaultAgentList] = useState(defaultAgentList1);

  const pipelineTypeDoc = useSelector((state) => state.pipelineTypeDoc);
  // const flowPipeline = useSelector((state) => state.flowPipeline);
  const drawer = useSelector((state) => state.drawer);
  const myFileList = useSelector((state) => state.myFileList);
  const selectedAIModel = useSelector((state) => state.selectedAIModel);
  const vectorTypeDB = useSelector((state) => state.vectorTypeDB);
  const isRFormate = useSelector((state) => state.isRFormate);
  const temperature = useSelector((state) => state.temperature);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);
  const isTypingEffect = useSelector((state) => state.isTypingEffect);
  const user = useSelector((state) => state.user);
  const isAgentDropdownOpen = useSelector((state) => state.isAgentDropdownOpen);

  useEffect(() => {
    if (user?.email) {
      const domain = getEmailDomain(user?.email);

      if (domain === "hellopraxis.com") {
        setDefaultAgentList([...defaultAgentList1, MUFGAg, PipGAg]);
      } else if (domain === "pip.global") {
        setDefaultAgentList([...defaultAgentList1, PipGAg]);
      } else if (domain === "linkgroup.com") {
        dispatch(setTypeOfAg(MUFGAg.agentName));
        setDefaultAgentList([...defaultAgentList1, MUFGAg]);
      } else if (user?.userRole === "student") {
        setDefaultAgentList([]);
      } else if (user?.userRole === "tutor") {
        // dispatch(setTypeOfAg(agentList?.[0]?.agentName));
        setDefaultAgentList([defaultAgentList1[0], markingAg]);
      }
    }
  }, [user, agentList]);

  const handleLogout = async () => {
    try {
      await signOut();
      dispatch(setDrawer(false));
      navigate("/");
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  return (
    <Drawer
      open={drawer}
      onClose={() => dispatch(setDrawer(false))}
      anchor="right"
      role="presentation"
      sx={{
        "& .MuiDrawer-paperAnchorRight": {
          marginTop: "env(safe-area-inset-top)",
          height: { xs: "90vh", sm: "100%", md: "100%" },
        },
      }}
    >
      <Box
        p="6px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid rgba(0, 0, 0, 0.12)"
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            paddingLeft: "16px",
          }}
        >
          Menu
        </Typography>
        <IconButton onClick={() => dispatch(setDrawer(false))}>
          <CloseSharpIcon />
        </IconButton>
      </Box>
      <Box sx={rootStyle}>
        <Box sx={{ paddingLeft: "16px" }}>
          <Button
            variant="text"
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "14px",
              fontWeight: 500,
              textTransform: "capitalize",
              mt: 2,
            }}
            endIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            New Chat
          </Button>

          <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
            <Typography fontWeight="500">Agent</Typography>

            <Box sx={{ ml: 6 }}>
              <Select
                value={typeOfAg}
                open={isAgentDropdownOpen}
                onClose={() => dispatch(setIsAgentDropdownOpen(false))}
                onOpen={() => dispatch(setIsAgentDropdownOpen(true))}
                onChange={(e) => {
                  dispatch(setTypeOfAg(e.target.value));
                  if (user?.userRole === "tutor") {
                    if (e.target.value === "Marking Agent") {
                      navigate("/uts-agent");
                    } else {
                      navigate("/dashboard");
                    }
                  }
                }}
                fullWidth
              >
                {[...defaultAgentList, ...agentList].map((item, i) => (
                  <MenuItem
                    value={item.agentName}
                    key={i}
                    onClick={() => dispatch(setDrawer(false))}
                  >
                    {item.agentName === "default"
                      ? "Standard"
                      : item.agentName === "meetingAssistant"
                      ? "Meeting Assistant"
                      : item.agentName === "UX"
                      ? "UX Assistant"
                      : item.agentName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          {user?.userRole !== "student" && (
            <>
              <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <Typography fontWeight="500">AI model</Typography>

                <Box sx={{ ml: 4 }}>
                  <AIModelSelect openAIAPIStatus={openAIAPIStatus} />
                </Box>
              </Box>
              {/* <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  labelPlacement="start"
                  sx={{ ml: "-2px" }}
                  control={
                    <Checkbox
                      checked={isTypingEffect}
                      onChange={(e) => {
                        dispatch(setIsTypingEffect(e.target.checked));
                      }}
                    />
                  }
                  label="Streaming text"
                />
              </Box> */}
              {user?.userRole !== "tutor" && (
                <Box sx={{ mt: 4 }}>
                  <Button
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    size="large"
                    onClick={() => {
                      dispatch(setDrawer(false));
                      navigate("/manegeAgent", {
                        state: { isAddAgent: true },
                      });
                    }}
                  >
                    Add Agent
                  </Button>
                  <Button
                    size="large"
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      dispatch(setDrawer(false));
                      navigate("/manegeAgent");
                    }}
                  >
                    Manage Agent
                  </Button>
                  {/* <Accordion>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                      <Typography>Admin</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        {user?.subscription?.planName === "Pro" && (
                          <>
                            <Button
                              variant="outlined"
                              sx={{ textTransform: "capitalize" }}
                              size="large"
                              onClick={() => {
                                dispatch(setDrawer(false));
                                navigate("/manegeAgent", {
                                  state: { isAddAgent: true },
                                });
                              }}
                            >
                              Add Agent
                            </Button>
                            <Button
                              size="large"
                              variant="outlined"
                              sx={{ textTransform: "capitalize" }}
                              onClick={() => {
                                dispatch(setDrawer(false));
                                navigate("/manegeAgent");
                              }}
                            >
                              Manage Agent
                            </Button>
                          </>
                        )}

                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => {
                            dispatch(setDrawer(false));
                            navigate("/Categorization");
                          }}
                        >
                          Categorization Agent
                        </Button>
                      </Box>
                      <Box
                        sx={{ mt: 4, display: "flex", alignItems: "center" }}
                      >
                        <Typography fontWeight="500">Pipeline</Typography>

                        <Box sx={{ ml: 6 }}>
                          <Select
                            value={pipelineTypeDoc}
                            fullWidth
                            onChange={(e) => {
                              if (myFileList >= 1) {
                                handleClickOpen();
                              }
                              if (e.target.value === "codeInterpreter") {
                                dispatch(
                                  setSelectedAIModel(
                                    AIModelList.find(
                                      (item) => item.model === "o3-mini"
                                    )
                                  )
                                );
                              }
                              dispatch(setPipelineTypeDoc(e.target.value));
                            }}
                          >
                            <MenuItem
                              value={"openaiFile"}
                              disabled={selectedAIModel.provider !== "openAI"}
                            >
                              OpenAI file assistant
                            </MenuItem>
                            <MenuItem
                              value={"codeInterpreter"}
                              disabled={selectedAIModel?.provider !== "openAI"}
                            >
                              OpenAI code interpreter
                            </MenuItem>
                            <MenuItem value={"langchain"}>
                              Langchain vector chain
                            </MenuItem>
                          </Select>
                        </Box>
                      </Box>

                      <Box
                        sx={{ mt: 4, display: "flex", alignItems: "center" }}
                      >
                        <Typography fontWeight="500">
                          Work Flow Type:
                        </Typography>
                        <Box sx={{ ml: 6 }}>
                          <Select
                            value={flowPipeline}
                            onChange={(e) => {
                              dispatch(setFlowPipeline(e.target.value));
                            }}
                            fullWidth
                          >
                            <MenuItem value={"langGraph"}>langGraph</MenuItem>
                            <MenuItem value={"funchain"}>func-Chain</MenuItem>
                          </Select>
                        </Box>
                      </Box>

                      <Box sx={{ mt: 4 }}>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              sx={{ ml: 6 }}
                              checked={isRFormate}
                              onChange={(e) =>
                                dispatch(setIsRFormate(e.target.checked))
                              }
                            />
                          }
                          label="Report format"
                          labelPlacement="start"
                          sx={{ ml: 0 }}
                        />
                      </Box>

                      {pipelineTypeDoc === "langchain" && (
                        <Box
                          sx={{ mt: 4, display: "flex", alignItems: "center" }}
                        >
                          <Typography fontWeight="500" mr={1}>
                            Select vector store
                          </Typography>
                          <StyledToggleButtonGroup
                            color="primary"
                            value={vectorTypeDB}
                            exclusive
                            onChange={(e) =>
                              dispatch(setVectorTypeDB(e.target.value))
                            }
                          >
                            <ToggleButton value="FAISS">
                              FAISS (faster)
                            </ToggleButton>
                            <ToggleButton value="chromadb">
                              Chroma (longer)
                            </ToggleButton>
                            {
                              <ToggleButton value="elasticSearch">
                                Elastic-Search
                              </ToggleButton>
                            }
                          </StyledToggleButtonGroup>
                        </Box>
                      )}

                      <Box
                        sx={{ mt: 4, display: "flex", alignItems: "center" }}
                      >
                        <Typography fontWeight="500" mr={1}>
                          Temperature
                        </Typography>
                        <Slider
                          value={temperature}
                          step={0.1}
                          min={0}
                          max={1}
                          valueLabelDisplay="auto"
                          onChange={(e, newvalue) =>
                            dispatch(setTemperature(newvalue))
                          }
                          sx={{ ml: 6 }}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion> */}
                </Box>
              )}
            </>
          )}
        </Box>

        <Box>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              cursor: "pointer",
            }}
          >
            {/* {`Subscription status: ${
              user["custom:stripeCusId"]
                ? user?.subscription?.status
                : "inactive"
            }`} */}
            {user?.email}
          </Typography>
          {user?.userRole !== "student" && (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setDrawer(false));
                window.open(
                  `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${user.email}`
                );
              }}
            >
              <SettingsIcon sx={{ mr: 2 }} />
              Settings
            </Typography>
          )}
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              cursor: "pointer",
            }}
            onClick={() => handleLogout()}
          >
            Log out
            <LogoutIcon sx={{ ml: 1 }} />
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
