import { createBrowserRouter, RouterProvider } from "react-router-dom";

import PrivateContainer from "./router/PrivateContainer";
import PublicContainer from "./router/PublicContainer";
import { ROUTES } from "./router";
import AppUrlListener from "./Components/AppUrlListener";

const router = createBrowserRouter(
  ROUTES.map(({ Component, isPrivate, path, tabTitle, isDashboard }) => ({
    path: path,
    element: isPrivate ? (
      <PrivateContainer tabTitle={tabTitle} isDashboard={isDashboard}>
        <Component />
      </PrivateContainer>
    ) : (
      <PublicContainer tabTitle={tabTitle}>
        <Component />
      </PublicContainer>
    ),
  }))
);

function App() {
  return (
    <>
      <AppUrlListener />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
