import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Box,
  Typography,
  Container,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { saveFileLocal } from "../utils";
import pdf from "../assets/Images/pdf.png";
import word from "../assets/Images/word.png";

const STEPS = {
  UPLOAD: "UPLOAD",
  MARKING: "MARKING",
  COMPLETE: "COMPLETE",
};
const supportedFormats = ["txt", "pdf", "docx", "csv", "json", "md", "xml"];

const UtsAgent = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [currentStep, setCurrentStep] = useState(STEPS.UPLOAD);
  const [rubrikUploaded, setRubrikUploaded] = useState(false);
  const [assessmentUploaded, setAssessmentUploaded] = useState(false);
  const [assessmentBriefUploaded, setAssessmentBriefUploaded] = useState(false);
  const [files, setFiles] = useState({
    assessmentBrief: null,
    rubrik: null,
    assessment: null,
  });
  const [comments, setComments] = useState("");
  const [messageText, setMessageText] = useState("");
  const [docError, setDocError] = useState("");
  const [download, setDownload] = useState(false);
  const [documentType, setDocumentType] = useState("");

  const isSubmitDisabled = !rubrikUploaded || !assessmentUploaded;

  const imageSrc =
    documentType === "pdf" ? pdf : documentType === "docx" ? word : null;

  const imageAlt =
    documentType === "pdf"
      ? "pdf"
      : documentType === "docx"
      ? "Word File"
      : null;

  const handleLoadingChange = (isLoading) => {
    setDownload(isLoading);
  };

  const handleFileUpload = (event, fileKey, setUploaded) => {
    setDocError("");
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!supportedFormats.includes(fileExtension)) {
      const errorMessage = `Invalid file format: ${file.name}. Please upload a supported file type.`;
      setDocError(errorMessage);
      return;
    }

    setFiles((prevFiles) => ({
      ...prevFiles,
      [fileKey]: file,
    }));
    setUploaded(true);
  };

  const handleAssessmentBriefUpload = (event) =>
    handleFileUpload(event, "assessmentBrief", setAssessmentBriefUploaded);
  const handleRubrikUpload = (event) =>
    handleFileUpload(event, "rubrik", setRubrikUploaded);
  const handleAssessmentUpload = (event) =>
    handleFileUpload(event, "assessment", setAssessmentUploaded);

  const handleRemoveDocument = (documentType) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [documentType]: null,
    }));

    if (documentType === "rubrik") setRubrikUploaded(false);
    else if (documentType === "assessment") setAssessmentUploaded(false);
    else if (documentType === "assessmentBrief")
      setAssessmentBriefUploaded(false);
  };

  const handleMarkAssessment = async () => {
    try {
      setCurrentStep(STEPS.MARKING);
      const formData = new FormData();
      formData.append("userId", user.sub);
      formData.append("comments", comments);
      Object.entries(files).forEach(([key, file]) => {
        if (file) {
          formData.append("files", file);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/AssessmentAgent`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessageText(response.data.response);
      setCurrentStep(STEPS.COMPLETE);
    } catch (error) {
      console.log(error);
      toast.error(error);
      setCurrentStep(STEPS.UPLOAD);
    }
  };

  const handleDownloadDocument = async (type) => {
    try {
      handleLoadingChange(true);
      setDocumentType(type);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/res_download`,
        {
          text: messageText,
          type: type,
          title: "Created by praxis/ai",
          emailList: [],
        },
        {
          responseType: "arraybuffer",
        }
      );
      saveFileLocal(response.data, response.headers["content-type"], type);
      setDocumentType("");
      handleLoadingChange(false);
    } catch (error) {
      setDocumentType("");
      handleLoadingChange(false);
      console.error(
        "Error during file download:",
        error.response || error.message
      );
      toast.error(error);
    }
  };

  const handleMarkAnother = () => {
    setCurrentStep(STEPS.UPLOAD);
  };

  const renderUploadStep = () => (
    <Box
      sx={{
        width: "100%",
        maxWidth: "950px",
        margin: "0px auto",
      }}
    >
      <Box sx={{ padding: { sm: "10px 50px" } }}>
        <Box
          sx={{
            textAlign: "center",
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "16px", sm: "18px" } }}
            gutterBottom
          >
            This is an AI assistant designed to help mark student assessments.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              width: { xs: "90%", sm: "70%", md: "50%" },
              mb: 2,
              textAlign: "start",
              fontSize: { xs: "14px", sm: "16px" },
            }}
          >
            1. Upload the student assessment brief
            <br />
            2. Upload the assessment rubric
            <br />
            3. Upload the student assessment
            <br />
            4. Add any notes or comments on the student's work
          </Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: "16px", mb: 2 }}
          >
            Documents
          </Typography>

          {!(
            rubrikUploaded &&
            assessmentUploaded &&
            assessmentBriefUploaded
          ) && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 2,
                }}
              >
                {[
                  {
                    label: "Assessment Brief",
                    uploaded: assessmentBriefUploaded,
                    onChange: handleAssessmentBriefUpload,
                  },
                  {
                    label: "Rubric",
                    uploaded: rubrikUploaded,
                    onChange: handleRubrikUpload,
                  },
                  {
                    label: "Student Assessment",
                    uploaded: assessmentUploaded,
                    onChange: handleAssessmentUpload,
                  },
                ].map(({ label, uploaded, onChange }, index) => (
                  <Button
                    key={index}
                    component="label"
                    variant="outlined"
                    sx={{
                      flexBasis: { xs: "100%", md: "calc(33.33% - 20px)" },
                      padding: "10px",
                      textTransform: "capitalize",
                    }}
                    disabled={uploaded}
                  >
                    {uploaded ? `${label} Uploaded` : `Upload ${label}`}
                    <input type="file" hidden onChange={onChange} />
                  </Button>
                ))}
              </Box>
              <Typography variant="body2" color="error">
                {docError}
              </Typography>
            </Box>
          )}

          <Box sx={{ padding: { sm: "0px 20px" }, mt: 2, width: "100%" }}>
            {[
              {
                label: "Assessmentbrief",
                uploaded: assessmentBriefUploaded,
                file: files.assessmentBrief,
                removeHandler: () => handleRemoveDocument("assessmentBrief"),
              },
              {
                label: "Rubrik",
                uploaded: rubrikUploaded,
                file: files.rubrik,
                removeHandler: () => handleRemoveDocument("rubrik"),
              },
              {
                label: "Assessment",
                uploaded: assessmentUploaded,
                file: files.assessment,
                removeHandler: () => handleRemoveDocument("assessment"),
              },
            ]
              .filter(({ uploaded }) => uploaded)
              .map(({ label, file, removeHandler }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "14px" },
                      mr: 1,
                      wordBreak: "break-all",
                    }}
                  >
                    {label}: {file?.name}
                  </Typography>
                  <IconButton size="small" onClick={removeHandler}>
                    <GridCloseIcon fontSize="small" sx={{ color: "red" }} />
                  </IconButton>
                </Box>
              ))}
          </Box>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom sx={{ fontSize: "16px" }}>
            Add any comments on the student assessment
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            sx={{ mb: 3 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              boxShadow: 2,
              padding: "10px 20px",
              textTransform: "capitalize",
            }}
            onClick={handleMarkAssessment}
            disabled={isSubmitDisabled}
          >
            Mark Assessment
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const renderMarkingStep = () => (
    <Box sx={{ textAlign: "center", width: "100%", mt: 4 }}>
      <Typography variant="h2" sx={{ marginBottom: "20px" }}>
        Marking student assessment...
      </Typography>
      <LinearProgress
        sx={{
          width: "25%",
          height: "8px",
          borderRadius: "20px",
          margin: "auto",
        }}
      />
    </Box>
  );

  const renderCompleteStep = () => (
    <Box sx={{ textAlign: "center", width: "100%", mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h2" sx={{ marginBottom: "20px" }}>
          Marking Complete
        </Typography>
        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "green",
            marginBottom: "20px",
          }}
        >
          <GridCheckIcon sx={{ color: "white", fontSize: "30px" }} />
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { sm: "row", xs: " column" },
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadDocument("pdf")}
            sx={{
              padding: "10px 20px",
              backgroundColor: "#757de8",
              boxShadow: 2,
              width: "250px",
            }}
          >
            Download As Pdf
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadDocument("docx")}
            sx={{
              padding: "10px 20px",
              backgroundColor: "#757de8",
              boxShadow: 2,
              width: "250px",
              textAlign: "center",
            }}
          >
            Download As Word File
          </Button>
        </Box>

        {download && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              padding: "10px 0px",
              width: "100%",
            }}
          >
            {imageSrc && (
              <img
                src={imageSrc}
                alt={imageAlt}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "4px",
                  objectFit: "cover",
                  animation: "pulse 1.5s infinite",
                }}
              />
            )}

            <Typography
              variant="body2"
              style={{ animation: "pulse-text 1.5s infinite" }}
            >
              {documentType === "send"
                ? "Sending email"
                : `Creating ${documentType} document...`}
            </Typography>

            <LinearProgress sx={{ width: "40%" }} />
          </Box>
        )}
        <Button
          variant="text"
          sx={{
            color: "blue",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
          onClick={handleMarkAnother}
        >
          Mark another assessment...
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          padding: { xs: "16px", sm: "20px" },
          display: { sm: "flex" },
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "12px", sm: "16px" },
            color: "#ffffff",
            backgroundColor: "rgb(69, 118, 250)",
            borderRadius: "30px",
            height: { xs: "34px", sm: "37px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            padding: "0 12px 0px 14px",
            maxWidth: "90px",
            position: { sm: "absolute" },
            left: "3%",
            top: "50%",
            transform: { sm: "translateY(-50%)" },
          }}
          onClick={() => navigate("/dashboard")}
        >
          praxis/ai
        </Typography>

        <Typography
          variant="h5"
          sx={{
            textAlign: { sm: "left", xs: "center" },
            fontSize: { xs: "14px", sm: "18px" },
            paddingTop: { sm: "0px", xs: "10px" },
          }}
        >
          Assessment marking assistant (Beta)
        </Typography>
      </Box>
      <Container maxWidth="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: { md: "calc(100vh - 77px)" },
            width: "100%",
            paddingTop: { xs: "20px", md: "0px" },
          }}
        >
          {currentStep === STEPS.UPLOAD && renderUploadStep()}
          {currentStep === STEPS.MARKING && renderMarkingStep()}
          {currentStep === STEPS.COMPLETE && renderCompleteStep()}
        </Box>
      </Container>
    </>
  );
};

export default UtsAgent;
