import React, { useEffect, useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { setTypeOfAg } from "../redux/reducer";
import { Box, MenuItem, Select } from "@mui/material";
import axios from "axios";

function StudentAgSetConfig() {
  const [open, setOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState("");

  const dispatch = useDispatch();
  const agentList = useSelector((state) => state.agentList);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (agentList && !user.studentAgConfig) {
      setOpen(true);
    } else {
      dispatch(
        setTypeOfAg(
          agentList.find((item) => item.id === user.studentAgConfig)?.agentName
        )
      );
    }
  }, [agentList]);

  const handleClose = () => setOpen(false);

  const onSubmit = async () => {
    try {
      const { data: userRes } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/studentAgSetConfig`,
        { email: user.email, AgId: selectedAgentId }
      );
      console.log(userRes);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="modal-modal-title" variant="h6" component="h2">
          Select Your subject
        </DialogTitle>
        <DialogContent>
          <Box>
            <Select
              value={selectedAgentId}
              onChange={(e) => setSelectedAgentId(e.target.value)}
            >
              {agentList.map((item, i) => (
                <MenuItem value={item.id} key={i}>
                  {`${item.agentLabel}  (${item.agentName}) `}
                </MenuItem>
              ))}
            </Select>
            <br />
            <Button variant="contained" onClick={onSubmit} sx={{ mt: 4 }}>
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StudentAgSetConfig;
