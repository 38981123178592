/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-2",
  aws_cognito_identity_pool_id:
    "ap-southeast-2:1e5320b9-06b3-4aeb-9918-04918048eb5a",
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_w6hwcYOt7",
  aws_user_pools_web_client_id: "2fjtqral6n5tjrc8ou8r5hkore",
  oauth: {
    domain: "hellopraxis-dev.auth.ap-southeast-2.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    // redirectSignIn: "praxisAI://localhost/",
    redirectSignIn: "https://ai.hellopraxis.com/,http://localhost:3000/",
    // redirectSignOut: "praxisAI://localhost/",
    redirectSignOut: "https://ai.hellopraxis.com/,http://localhost:3000/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ["GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "upload-user-files111605-dev",
  aws_user_files_s3_bucket_region: "ap-southeast-2",
};

export default awsmobile;
