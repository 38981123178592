import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsNewChatModel,
  setIsClearChatData,
  setMyFileList,
  setSidebarClose,
  setSelectedAIModel,
  setTypeOfAg,
} from "../redux/reducer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AIModelList } from "./AIModelSelect";
import theme from "../muiTheme";

const StartNewChat = ({ fnCall }) => {
  const dispatch = useDispatch();
  const myFileList = useSelector((state) => state.myFileList);
  const isNewChatModel = useSelector((state) => state.isNewChatModel);
  const user = useSelector((state) => state.user);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(setIsNewChatModel(false));
  };

  return (
    <Dialog
      open={isNewChatModel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: "rgba(0, 0, 0, 0.87)",
          fontSize: "20px",
          fontWeight: 500,
        }}
      >
        Start a new chat
      </DialogTitle>
      <DialogContent>
        <Typography
          mt={2}
          sx={{ color: "#000", fontSize: "16px", fontWeight: 400 }}
        >
          Your chat will be available in chat history.
        </Typography>
        {myFileList === 1 && (
          <Typography
            mt={2}
            sx={{ color: "#000", fontSize: "16px", fontWeight: 400 }}
          >
            Would you like to remove with the uploaded file?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{ textTransform: "capitalize", color: "rgba(0, 0, 0, 0.87)" }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: "capitalize",
            color: theme.palette.primary.main,
            padding: "10px",
            fontSize: "14px",
            fontWeight: 500,
          }}
          onClick={() => {
            dispatch(setIsClearChatData(true));
            dispatch(setMyFileList(0));
            axios.post(
              `${process.env.REACT_APP_API_URL}/api/chat/clearSession`,
              { userId: user.sub, typeOfAg: typeOfAg }
            );
            navigate("/dashboard");
            const isMobile = window.innerWidth <= 768;
            if (isMobile) {
              dispatch(setSidebarClose(false));
            }
            dispatch(setSelectedAIModel(AIModelList[0]));
            handleClose();
            fnCall();
          }}
        >
          New Chat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartNewChat;
