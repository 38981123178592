import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { saveAs } from "file-saver";

export const saveFileLocal = (response, contentType, extension) => {
  const blob = new Blob([response], {
    type: contentType,
  });
  const filename = `${Date.now()}.${extension}`;

  if (Capacitor.isNativePlatform()) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result.split(",")[1];
      const savedFile = await Filesystem.writeFile({
        path: filename,
        data: base64data,
        directory: Directory.Documents,
      });
      await Share.share({
        url: savedFile.uri,
      });
    };
    reader.readAsDataURL(blob);
  } else {
    saveAs(blob, filename);
  }
};

export function getEmailDomain(email) {
  if (!email) return;
  const parts = email.split("@"); // Split the email address at the "@" symbol
  return parts[1];
}
